Foundation.Abide.defaults.patterns['alpha'] = /^([a-zäöüßA-ZÄÖÜ \-\_]+[\s]*)+$/;
Foundation.Abide.defaults.patterns['alpha_numeric'] = /^([a-zäöüßA-ZÄÖÜ0-9 \!\?\.\,\-\_\+\(\)]+[\s]*)+$/;
Foundation.Abide.defaults.patterns['phone'] = /^([0-9 \+]+[\s]*)+$/;
Foundation.Abide.defaults.patterns['zip'] = /^([0-9]{4,5})$/;

$(document).foundation();

$(function () {
    var $voyageList = $('#voyage-list');
    var $destinationList = $('#destination-list');
    var $bookBtn = $('.book-btn');
    var $bookingForm = $('#booking-form form');
    var $galleries = document.querySelectorAll('.impression-gallery__image, .gallery__image a');
    var $progressBarFractions =  $('.progress-bar .progress-bar__fraction')

    $('.js-drilldown-back a').each(function(){
        $(this).text('zurück');
    });

    $bookBtn.on('click', function (e) {
        $([document.documentElement, document.body]).animate({
            scrollTop: $bookingForm.offset().top-200
        }, 400);
    });

    let slider = $('.image-slider').slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        asNavFor: '.content-slider',
        dots: false,
        adaptiveHeight: true,
    });

    slider.on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        $progressBarFractions.removeClass('reached')

        $.each($progressBarFractions, function(i, fraction) {
            if(i > nextSlide)
                return false;

            $(fraction).addClass('reached');
        });
    })

    $('.content-slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        cssEase: 'linear',
        ease: 'linear',
        arrows: false,
        asNavFor: '.image-slider',
        dots: false,
        adaptiveHeight: true,
    });

    const returnHomePicker = $('#quote_request_form_returnHomeDate').datepicker({
        minDate: new Date(),
        language: 'de'
    });

    const returnPicker = $('#quote_request_form_returnDate').datepicker({
        minDate: new Date(),
        language: 'de',
        onSelect: function (fd, date) {
            returnHomePicker.data('datepicker').selectDate(date);
            returnHomePicker.data('datepicker').update('minDate', date)
        }
    });

    $('#quote_request_form_departureDate').datepicker({
        minDate: new Date(),
        language: 'de',
        onSelect: function (fd, date) {
            returnPicker.data('datepicker').selectDate(date);
            returnPicker.data('datepicker').update('minDate', date);
        }
    });

    if($galleries.length > 0)
        new LuminousGallery($galleries);


    if($voyageList.length > 0)
        new Voyages($voyageList)

    if($destinationList.length >0)
        new Destinations($destinationList)

    if($bookingForm.length >0) {
        if(window.location.hash == '#book') {
            $('#screen-cover').foundation('toggle');
            $('#voyage-details').foundation('toggle');
        }

        new MultiPartFormHandler($bookingForm)
    }
});
