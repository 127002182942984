class MultiPartFormHandler {

    constructor($form) {
        this.closeEventRegistered = false;
        this.closeEvent = function (e) {
            e.preventDefault();
            e.returnValue = '';
        };

        this.form = {};
        this.initForm($form);
        this.activePart = {};
        this.fieldIndex =[];
        this.$body = $('body');

        this.nAdditionalPersons = 0;
        this.selectedOptions = { 'booking-option': 0 };
        this.$additionalPersons = $('#additional-persons');
        this.$additionalPersonOptions = $('#additional-person-options');
        this.$personOptions = $('#person-options');
        this.options = {
          $basePrice: this.$personOptions.find('#base-price-options'),
          $roomSurcharge: this.$personOptions.find('#room-surcharge-options'),
          $ticketORice: this.$personOptions.find('#ticket-price-options'),
          $priceRelief: this.$personOptions.find('#price-relief-options')
        };
        this.$passengerCount = $('[id$="passengerCount"]');
        this.$addPerson = $('#add-person');
        this.additionalPersonFields = this.$additionalPersons.data('fields');

        this.summary = {
            $option:        this.form.$el.find('#booking-option'),
            $personCount:   this.form.$el.find('#passenger-count')
        }

        this.validationPatterns = {
            'alpha': /^[a-zäöüßA-ZÄÖÜ \-\_]+$/,
            // 'alpha_numeric': /^[a-zäöüßA-ZÄÖÜ0-9 \-\_\.\,\"\'\:\?\!]+$/,
            'alpha_numeric': /^[a-zäöüßA-ZÄÖÜ0-9 \,\"\'\:\!\?\.\,\-\_\+\(\)]+$/,
            'number': /^[0-9 \+]+$/,
            'zip': /^[0-9]{4,5}$/,
            // 'date': /^(?:30))|(?:(?:0[13578]|1[02])-31)\.(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])\.(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])$/,
            'email': /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/,
        };

        this.initParts();
        this.registerListeners();
    }

    /**
     * init form
     */
    initForm($form) {
        this.form = {
            $el: $form,
            $parts: $form.find('fieldset'),
            parts: [],
            isValid: function () {
                // check if any part is not valid
                return !(this.parts.findIndex(part => {
                    return part.isValid() === false
                }) >= 0);
            },
            validate: function () {
                // disable last proceed button
                this.parts[this.parts.length-1].$proceed.prop('disabled', !this.isValid())
            }
        }
    }

    /**
     * initialise all form parts
     */
    initParts() {
        let me = this;

        // find all parts
        $.each(me.form.$parts, function (i, fieldset) {
            let $fieldset = $(fieldset);

            // create part data
            var curPart = {
                $el: $fieldset,
                rank: i,
                accessible: (i === 0),
                completed: false,
                isLast: $fieldset.find('button.proceed').hasClass('submit'),
                $content: $fieldset.find('.part-content'),
                $proceed: $fieldset.find('button.proceed'),
                fields: [],
                isValid: function() {
                    // check if any field is not valid
                    return !(this.fields.findIndex(field => {
                        return field.valid === false
                    }) >= 0);
                },
                validate: function () {
                    let valid = this.isLast? me.form.isValid(): this.isValid();
                    // disable proceed button if part is not already competed
                    if(!this.completed)
                        this.$proceed.prop('disabled', !valid)

                    // add error
                    if(!valid)
                        this.$el.addClass('error');
                    else
                        this.$el.removeClass('error');
                }
            };

            // init all fields in part
            me.initPartFields(curPart);
            // add to parts
            me.form.parts.push(curPart);
            // register all part listeners
            me.registerPartListeners(curPart);
        });

        // set first as active part
        me.activePart = me.form.parts[0];
    }

    /**
     * init all part fields
     * @param part
     */
    initPartFields(part) {
        let $fields = part.$el.find('input, textarea');

        $.each($fields, function (i, fieldEl) {
            this.initField(part, $(fieldEl));
        }.bind(this))
    }

    /**
     * init field
     * @param part
     * @param $field
     */
    initField(part, $field) {
        let name = $field.attr('name');

        // check for multiple fields with the same name
        let field = part.fields.find(f => {
            return f.name === name
        });

        // add to fields
        if (field === undefined) {
            let required = !!$field.attr('required');

            // add field and its data
            field = {
                name: name,
                required: required,
                pattern: $field.attr('pattern'),
                valid: !required,
                value: null,
                $fields: $('[name="' + name + '"]'),
                event: null
            };

            // register listener
            field.event = field.$fields.on('keyup change focusout', function (e) {
                // validate field
                this.validateField($(e.target), field);
                // validate part
                // validate part
                part.validate();
            }.bind(this))

            // add field to part
            part.fields.push(field);
            // validate part
            part.validate();

            this.fieldIndex.push({
                'field': field,
                'part': part
            });
        }

        return field;
    }

    /**
     * remove field from part
     * @param part
     * @param name
     */
    removeFieldFromPart(part, field) {
        // remove listeners
        field.$fields.off('keyup change focusout');
        // remove field from part
        part.fields = part.fields.filter(f => f.name !== field.name);
        // remove field from field index
        this.fieldIndex = this.fieldIndex.filter(f => f.field.name !== field.name);
    }

    /**
     * register listeners
     */
    registerListeners() {
        let me = this;

        if((this.$additionalPersons.length > 0) && (this.$addPerson.length > 0))
        {
            // get current part
            let part = this.getPartByFieldset(this.$addPerson.parents('fieldset'));

            this.$addPerson.on('click', function (e) {
                e.preventDefault();

                this.addAdditionalPerson(part);
            }.bind(this))
        }

        // get booking option fields
        let optionsField = this.fieldIndex.find(f => f.field.name === 'booking-option');
        // ensure they exist
        if(optionsField !== undefined) {
            // append listener and change summary if it does
            optionsField.field.$fields.on('change', function () {
                me.selectedOptions['booking-option'] = this.value;
                me.renderSummaryOptions();
            })
        }


        if(this.$passengerCount.length > 0) {
            this.$passengerCount.on('change', function () {
                me.summary.$personCount.html(this.value);
            })
        }
    }

    /**
     * register part listeners
     * @param part
     */
    registerPartListeners(part) {
        let me = this;

        // register part accordion open click
        part.$el.find('.legend').on('click', function () {
            // only show part if accessible
            if(!part.accessible)
                return;

            // validate the form when last part is opened
            if(part.isLast) {
                me.form.validate()
            }

            me.activePart = part;
            me.form.$parts.removeClass('active');
            part.$el.addClass('active');

            me.scrollToPart(part);
        });

        // register part proceed click
        if(part.isLast) {
            part.$proceed.on('click', function (e) {
                e.preventDefault();

                // ensure form is valid
                if(me.form.isValid()) {
                    me.$body.addClass('xhr-pending');

                    $.ajax({
                        url: '/api/booking/book',
                        method: 'post',
                        data: me.form.$el.serialize()
                    }).done(function (xhr) {
                        if(xhr.successful) {
                            window.removeEventListener('beforeunload', me.closeEvent);
                            window.location.href = xhr.redirect;
                        } else {
                            me.xhrErrorHandling(xhr.errors);
                            me.$body.removeClass('xhr-pending');
                        }
                    }).fail(function (xhr) {
                        me.$body.removeClass('xhr-pending');
                    })
                }

            });
        } else {
            part.$proceed.on('click', function (e) {
                e.preventDefault();

                // register history back/tab close notice
                if(!me.closeEventRegistered) {
                    me.closeEventRegistered = true;
                      window.addEventListener('beforeunload', me.closeEvent)
                }

                // only proceed if part was completed before or is valid
                if (part.completed || part.isValid()) {
                    part.completed = true;
                    part.$el.addClass('completed accessible');

                    // go to next step
                    me.activePart = me.getPartByFieldset(part.$el.next());
                    me.activePart.accessible = true;
                    me.form.$parts.removeClass('active');
                    me.activePart.$el.addClass('active accessible');
                }

                me.scrollToPart(part);
            });
        }
    }

    /**
     * validate field
     * @param $field
     * @param fieldData
     * @returns {boolean}
     */
    validateField($field, fieldData) {
        fieldData.valid = false;
        fieldData.value = $field.val();


        if($field.attr('type') === 'checkbox') {
            if(fieldData.required && !$field.is(':checked')) {
                $field.addClass('error');
                return false;
            } else {
                $field.removeClass('error');
                fieldData.valid = true;
                return true
            }
        } else {
            if (fieldData.value.length < 1) {
                if(fieldData.required) {
                    $field.addClass('error')
                    return false;
                } else {
                    fieldData.valid = true;
                    return true;
                }
            }

            // try to get validation pattern for field
            let pattern = this.validationPatterns[fieldData.pattern];

            if (pattern !== undefined) {
                if (!pattern.test(fieldData.value)) {
                    $field.addClass('error');
                    return false;
                }
            }

            $field.removeClass('error');
            fieldData.valid = true;
        }
    }

    /**
     * scroll to part
     * @param part
     */
    scrollToPart(part) {
        $([document.documentElement, document.body]).animate({
            scrollTop: part.$el.offset().top - 200
        }, 400);
    }

    /**
     * add new additional person
     * @param part
     */
    addAdditionalPerson(part) {
        let $additionalPerson = $('<div>').attr({
            class: 'additional-person'
        }).appendTo(this.$additionalPersons);

        $('<div class="additional-person__legend">').html(this.$additionalPersons.data('title')).appendTo($additionalPerson);

        // add remove button
        let $remove = $('<button>').attr('class', 'remove').appendTo($additionalPerson);

        let fields = [];

        $.each(this.additionalPersonFields, function (i, fieldConfig) {
            if(fieldConfig.name === 'birthdate') {
                this.generateBirthdayField().appendTo($additionalPerson);
            } else {
                // generate field
                let fieldConstruct = this.generateInputField(fieldConfig);

                let $container = $('<div class="field"></div>');

                // add label to additional person container
                if(fieldConstruct.$label)
                    fieldConstruct.$label.appendTo($container);

                // add field to additional person container
                fieldConstruct.$field.appendTo($container);

                $container.appendTo($additionalPerson);

                // init part field
                fields.push(this.initField(part, fieldConstruct.$field));
            }
        }.bind(this))

        this.summary.$personCount.html(this.$additionalPersons.find('.additional-person').length +1);

        this.addAdditionalPersonOptions();

        // increment additional persons
        this.nAdditionalPersons++;
        const personIndex = this.nAdditionalPersons

        // add remove click listener
        $remove.on('click', function (e) {
            e.preventDefault();

            // remove all fields from part
            $.each(fields, function (i, field) {
                this.removeFieldFromPart(part, field)
            }.bind(this))

            this.removeAdditionalPersonOptions(personIndex);

            // remove additional person element
            $additionalPerson.remove();
            // revalidate part
            part.validate();

            this.summary.$personCount.html(this.$additionalPersons.find('.additional-person').length+1);

        }.bind(this))

        // disable proceed button
        if(!part.completed)
            part.$proceed.prop('disabled', true);
    }

    /**
     * add options for each additional passenger
     */
    addAdditionalPersonOptions() {
        // let $options = this.$personOptions.clone();
        let me = this;

        let $options = $('<div></div>')
        $options.attr('id', 'additional_person_options_' + this.nAdditionalPersons);

        $options.prepend('<span class="passenger-type">' + this.$additionalPersons.data('title') + '</span>')

        let name = 'additional_person_' + this.nAdditionalPersons + '_booking-option';

        this.selectedOptions[name] = 0;
        this.renderSummaryOptions();

        $.each(this.options, function (i, field) {
            if(field === undefined)
                return;

            let $field = field.clone();

            $field.attr('id', $field.attr('id') + '_' + this.nAdditionalPersons)

            let $select = $field.find('select');

            $select.attr({
                name: 'additional_person[' + this.nAdditionalPersons + '][' + $select.attr('name') +']'
            })

            $options.append($field);

            // $field.find('input').prop('checked', (i === 0))
            //
            // $field.find('label').attr({
            //     for: 'additional_person_booking-options_' + this.nAdditionalPersons + '_' + i
            // })
        }.bind(this))

        $options.find('select').on('change', function () {
            me.selectedOptions[this.name] = this.value;
            me.renderSummaryOptions();
        })

        this.$additionalPersonOptions.append($options);
    }

    /**
     * remove options for additional passenger
     * @param index
     */
    removeAdditionalPersonOptions(index) {
        // index is always one smaller than given
        index--;

        // remove option fields
        this.$additionalPersonOptions.find('#additional_person_options_' + index).remove();
        // remove from selected options
        delete this.selectedOptions['additional_person_' + index + '_booking-option'];
        // generate new summary
        this.renderSummaryOptions();
    }

    /**
     * generate input field and label
     * @param name
     * @param label
     * @returns {{$field: (jQuery|undefined), $label: (jQuery|string)}}
     */
    generateInputField(fieldConfig) {

        let $label;

        // copy or generate label
        if(fieldConfig.label !== undefined) {
            let $masterLabel = $('label[for="_booking_form_' + fieldConfig.name + '"]');

            if ($masterLabel.length > 0) {
                $label = $masterLabel.clone().attr({
                    for: 'additional_person_' + fieldConfig.name + '_' + this.nAdditionalPersons
                });
            } else {
                let $label = $('<label>').attr({
                    for: 'additional_person_' + fieldConfig.name + '_' + this.nAdditionalPersons
                }).text(fieldConfig.label);
            }
        }

        let $field;
        let $masterField = $('[name$="_booking_form[' + fieldConfig.name + ']"');

        // copy or generate field
        if($masterField.length > 0) {
            $field = $masterField.clone().attr({
                id: 'additional_person_' + fieldConfig.name + '_' + this.nAdditionalPersons,
                name: 'additional_person[' + this.nAdditionalPersons +'][' + fieldConfig.name + ']',
            });

            if($field.prop('nodeName').toUpperCase() === 'INPUT'){
                $field.val('');
            } else
                // always use same pickup location as default as the master
                if(fieldConfig.name === 'pickupLocation') {
                    $field.prop('selectedIndex', $masterField.prop('selectedIndex'))
                }

        } else {
            $field = $('<input>').attr({
                type: 'text',
                id: 'additional_person_' + fieldConfig.name + '_' + this.nAdditionalPersons,
                name: 'additional_person[' + this.nAdditionalPersons + '][' + fieldConfig.name + ']',
                pattern: 'alpha'
            })

            if(fieldConfig.placeholder !== undefined)
                $field.attr({ placeholder: fieldConfig.placeholder })
        }

        // mark field as required or not required
        if(fieldConfig.required !== undefined) {
            let required = (fieldConfig.required === true);
            $field.prop('required', required);

            if($label) {
                if (required)
                    $label.addClass('required')
                else
                    $label.removeClass('required')
            }
        }

        return {
            $label: $label,
            $field:  $field
        };
    }

    /**
     * generate birthday field
     */
    generateBirthdayField() {

        let $birthdateContainer = $('<div class="field">');

         $('<label>').attr({
            class: 'required'
        }).text('Geburtstag').appendTo($birthdateContainer);

        $('select[id$="birthdate_day"]').clone().attr({
            id: null,
            name: 'additional_person[' + this.nAdditionalPersons +'][birthdate][day]'
        }).appendTo($birthdateContainer);

        $birthdateContainer.append('.')

        $('select[id$="birthdate_month"]').clone().attr({
            id: null,
            name: 'additional_person[' + this.nAdditionalPersons +'][birthdate][month]'
        }).appendTo($birthdateContainer);

        $birthdateContainer.append('.')

        $('select[id$="birthdate_year"]').clone().attr({
            id: null,
            name: 'additional_person[' + this.nAdditionalPersons +'][birthdate][year]'
        }).appendTo($birthdateContainer);

        return $birthdateContainer;
    }

    /**
     * get part by fieldset element
     * @param $fieldset
     * @returns {T}
     */
    getPartByFieldset($fieldset) {
        let partRank = this.form.$parts.index($fieldset);

        return this.form.parts.find(part => { return part.rank === partRank });
    }

    /**
     * render options summary
     */
    renderSummaryOptions() {
        let summary = {};

        $.each(this.selectedOptions, function (i, option) {
            if(summary[option] === undefined) {
                summary[option] = {
                    count: 1,
                    name: this.$personOptions.children('div.option').eq(option).find('label').html()
                };
            } else {
                summary[option].count++;
            }
        }.bind(this))

        let summaryStr = '';

        $.each(summary, function (i, option) {
            if(summaryStr.length > 0)
                summaryStr += ', ';

            summaryStr += option.count + 'x ' + option.name
        });

        this.summary.$option.html(summaryStr);
    }

    /**
     * xhr error handling
     * @param errors
     */
    xhrErrorHandling(errors) {
        let errorFieldNames = '<ul class="error__fields">';

        $.each(errors, function (i, error) {
            let fieldIndex = this.fieldIndex.find(field => { return field.field.name == error.field })
            errorFieldNames += '<li>' + error.fieldLabel + '</li>';

            if(fieldIndex === undefined)
                return;

            $.each(fieldIndex.field.$fields, function(i, field) {
                let $field = $(field);

                if(i === 0) {
                    $field.after('<span class="error__message">' +
                        error.error +
                        '</span>')
                }

                $field.addClass('error')
            });

            fieldIndex.part.$el.addClass('error');

        }.bind(this));

        this.form.parts[this.form.parts.length-1].$el.append(errorFieldNames + '</ul>');
    }
}
