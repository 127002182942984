class Voyages {

    /**
     * constructors
     * @param $voyageList
     */
    constructor($voyageList) {

        this.$voyageList = $voyageList;

        this.voyages = voyageData;
        this.$voyages = this.$voyageList.find('[data-voyage-id]');
        this.activeVoyages = this.voyages;

        this.dateSearch = '';
        this.destinationSearch = '';
        this.categorySearch = 0;

        this.$searchDate = $('select[name="start-date"]');
        this.$searchDestination = $('input[name="destination-search"]');
        this.$searchCategory = $('select[name="category-search"]');

        this.$detailWindow = $('#list-item-details .list-teaser');
        this.detailWindow = {
            '$cover': this.$detailWindow.find('#cover'),
            '$duration': this.$detailWindow.find('#duration'),
            '$date': this.$detailWindow.find('#date'),
            '$mainDestinations': this.$detailWindow.find('#main-destinations'),
            '$description': this.$detailWindow.find('#description')
        };

        this.curAnimation = null;

        this.registerListeners();
    }

    /**
     * register listeners
     */
    registerListeners() {
        let me = this;

        /**
         * on location keydown
         */
        if(this.$searchDestination.length > 0) {
            this.$searchDestination.on('keyup', function (e) {
                me.destinationSearch = me.$searchDestination.val();

                let voyages = me.filterByDestination(me.destinationSearch);

                if (me.dateSearch !== '')
                    voyages = me.filterByDate(me.dateSearch, voyages);

                me.applyFiler(voyages);
            });
        }

        /**
         * on category change
         */
        if(this.$searchCategory.length > 0) {
            this.$searchCategory.on('change', function (e) {
                me.categorySearch = this.value;

                let voyages = me.filterByCategory(me.categorySearch);

                if (me.dateSearch !== '')
                    voyages = me.filterByDate(me.dateSearch, voyages);

                me.applyFiler(voyages);
            });
        }

        /**
         * on date select change
         */
        if(this.$searchDate.length > 0) {
            this.$searchDate.on('change', function (e) {
                me.dateSearch = this.value;

                let voyages = me.filterByDate(this.value);

                if (me.destinationSearch !== '')
                    voyages = me.filterByDestination(me.destinationSearch, voyages);
                else if (me.categorySearch > 0)
                    voyages = me.filterByCategory(me.categorySearch, voyages);


                me.applyFiler(voyages);
            });
        }

        /**
         * show voyage details
         */
        if(!this.$voyageList.hasClass('editmode')) {
            this.$voyages.on('mouseenter', function (e) {
                me.updateDetailWindow(parseInt($(this).data('voyage-id')))
            });
        }
    }

    /**
     * update detail window by voyage id
     * @param id
     */
    updateDetailWindow(id) {
        let voyage = this.voyages.find(voyage => { return voyage.id == id });
        let me = this;

        // preload image
        $('<img src="' + voyage.cover + '" />').on('load', function () {

            let img = this;

            // ensure current animation is stopped
            if(me.curAnimation) {
                me.curAnimation.stop();
            }

            // fade out
            me.curAnimation = me.$detailWindow.animate({
                opacity: 0,
                left: '-200px'
            }, 400, function () {
                // reset cur animation
                me.curAnimation = null;
                // update window
                me.detailWindow.$cover.attr('src', img.src)
                me.detailWindow.$duration.html(voyage.duration + ' ' + voyage.durationUnit);
                me.detailWindow.$date.html(((voyage.dateName.length > 0)? voyage.dateName + ' | ': '') + voyage.date);
                me.detailWindow.$mainDestinations.html(voyage.detailName? voyage.detailName : voyage.mainDestinations);
                me.detailWindow.$description.html(voyage.shortDescription? voyage.shortDescription : voyage.secondaryDestinations);

                // set fade in options
                me.curAnimation = me.$detailWindow.css({ left: '200px' });
                // fade in
                me.$detailWindow.animate({
                    opacity: 1,
                    left: 0
                }, 500, function () {
                    // reset cur animation
                    me.curAnimation = null;
                });
            });
        });
    }

    /**
     * filter voyages by destinations
     * @param value
     * @param voyages
     * @returns {*}
     */
    filterByDestination(value, voyages) {

        if(voyages === undefined)
            voyages = this.voyages;

        if(value.length < 3) {
            return voyages;
        }

        let pattern = new RegExp(value, 'i');

        return voyages.filter(function(voyage) {
            // search for destination
            return voyage.destinations.find(destination => pattern.test(destination))
        });
    }

    /**
     * filter voyages by date
     * @param date
     * @param voyages
     * @returns {*}
     */
    filterByDate(date, voyages) {

        if(voyages === undefined)
            voyages = this.voyages;

        if(date == 0)
            return voyages;

        return voyages.filter(voyage => { return voyage.filterDate == date });
    }

    filterByCategory(categoryId, voyages) {

        if(voyages === undefined)
            voyages = this.voyages;

        if(categoryId <= 0)
            return voyages;


        return voyages.filter(voyage => { return voyage.categories.indexOf(categoryId) !== -1 });
    }

    /**
     * apply filter to given
     * @param voyages
     */
    applyFiler(voyages) {
        this.$voyages.hide();

        $.each(voyages, function (i, voyage) {
            if(voyage.$el === undefined) {
                voyage.$el = this.$voyages.filter("[data-voyage-id='" + voyage.id + "']")
            }

            voyage.$el.show();
        }.bind(this));
    }
}
