class Destinations {

    /**
     * constructor
     * @param $destinationList
     */
    constructor($destinationList) {

        this.$destinationList = $destinationList;
        this.$destinations = this.$destinationList.find('[data-destination]');
        this.$searchDestination = $('input[name="destination-search"]');

        this.$detailWindow = $('#list-item-details .list-teaser');
        this.detailWindow = {
            '$cover': this.$detailWindow.find('#cover'),
            '$intro': this.$detailWindow.find('#intro'),
            '$name': this.$detailWindow.find('#name'),
            '$description': this.$detailWindow.find('#description')
        };

        this.destinations = [];
        this.curAnimation = null;

        this.initDestinations();
        this.registerListeners();
    }

    /**
     * init destinations
     */
    initDestinations() {
        let me = this;

        $.each(this.$destinations, function (i, destination) {
            let $el = $(destination);
            me.destinations.push({
                '$el': $el,
                'name': $el.data('destination')
            });
        })
    }

    /**
     * register listeners
     */
    registerListeners() {
        let me = this;

        /**
         * on location keydown
         */
        if(this.$searchDestination.length > 0) {
            this.$searchDestination.on('keyup', function (e) {
                me.filterDestinations(me.$searchDestination.val());

            });
        }


        /**
         * show voyage details
         */
        if(!this.$destinationList.hasClass('editmode')) {
            this.$destinations.on('mouseenter', function (e) {
                let $el = $(this);
                me.updateDetailWindow({name: $el.data('destination'), cover: $el.data('cover')})
            });
        }
    }

    /**
     * update detail window by voyage id
     * @param id
     */
    updateDetailWindow(details) {
        let me = this;

        // preload image
        $('<img src="' + details.cover + '" />').on('load', function () {

            let img = this;

            // ensure current animation is stopped
            if(me.curAnimation) {
                me.curAnimation.stop();
            }

            // fade out
            me.curAnimation = me.$detailWindow.animate({
                opacity: 0,
                left: '-200px'
            }, 400, function () {
                // reset cur animation
                me.curAnimation = null;
                // update window
                me.detailWindow.$cover.attr('src', img.src);
                me.detailWindow.$name.html(details.name);
                me.detailWindow.$intro.html('');
                me.detailWindow.$description.html('');

                // set fade in options
                me.curAnimation = me.$detailWindow.css({ left: '200px' });
                // fade in
                me.$detailWindow.animate({
                    opacity: 1,
                    left: 0
                }, 500, function () {
                    // reset cur animation
                    me.curAnimation = null;
                });
            });
        });
    }

    /**
     * filter destinations
     * @param value
     */
    filterDestinations(value) {
        if(value.length < 3)
        {
            this.$destinations.show();
            return;
        }

        this.$destinations.hide();

        let pattern = new RegExp(value, 'i');

        $.each(this.destinations, function (i, destination) {
            if(pattern.test(destination.name))
                destination.$el.show()
        })

    }
}